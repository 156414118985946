div.beer-list {
  padding: 16px;
}

.all-beer-header {
  display: flex;
  justify-content: space-between;

  h3.ant-typography {
    display: inline-block;
  }

  .beer-list-filters {
    margin: 8px;
    padding: 8px 16px;
    align-self: flex-end;
    display: flex;

    > div > div {
      justify-content: flex-end;
      background-color: transparent;
      box-shadow: none;
    }

    button[class*="rootPrimaryVariant"]:not(:disabled) {
      background-color: #1890ff;
      text-transform: none;
    }

    div[role="dialog"] {
      background: whitesmoke;
      div[class*="header"] {
        background: #5aadf9;
      }
    }

    &.loading {
      height: 76px;
      text-align: center;
    }
  }
}
