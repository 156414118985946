@import "~antd/dist/antd.css";

div.App {
  margin: 10px auto;
  margin-top: 0;
  max-width: 900px;

  .ant-page-header {
    background-color: #5aadf9;
    color: #fff;
    padding: 16px;
    z-index: 20;
    box-shadow: 1px 1px 6px 0px rgba(150, 170, 180, 0.5);
  }

  .ant-page-header h1.ant-typography {
    color: #fff;
  }
}

span.alcohol-toggle {
  label {
    margin-right: 12px;
    button {
      margin-right: 8px;
    }
  }
}

.random-beer,
.beer-item {
  box-shadow: 1px 1px 6px 0px rgba(150, 170, 180, 0.5);
  border-radius: 20px;
  margin: 8px;
  padding: 8px 16px;
  height: auto;
}

h1 {
  margin-left: 8px;
}
