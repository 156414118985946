div.beer-item {
  margin-bottom: 12px;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 25px 27px auto;
  min-height: 144px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #1890ff !important;
  }

  div.image {
    grid-row-start: 1;
    grid-row-end: 4;
    min-width: 50px;
    img {
      margin: 8px auto;
      display: block;
    }
  }

  div.name {
    margin: 0px 24px;
    h3 {
      color: #1890ff;
      max-width: calc(100% - 70px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  div.description {
    margin: 0px 24px;
    grid-column-start: 2;
    grid-column-end: 3;
    max-width: calc(100% - 70px);
    text-align: justify;
    text-align-last: left;
  }

  span.abv {
    color: #a9a9a9;
    margin: 4px 24px;
  }
}
